/**
 * @package		Al hirafiyin web app
 * @desc		Main script
 *
 * @copyright	Copyright (C) 2022 Jaayfer abderrahim, Inc. All rights reserved.
 * @license		see LICENSE.txt
 * Todo : ERRORSERVERUNAVAILABLE is not defined
 */
// import js libraries
import Framework7 from 'framework7/framework7.esm.bundle.js';
import Utilities from './commons/js/utilities';
import $ from 'jquery';
import $$ from 'dom7';
import 'jquery.actual';
import craftsmanTools from './commons/js/craftsman-tools.js';

// import css libraries
import 'animate.css';
import './libraries/framework7/css/framework7.bundle.rtl.css';
import './libraries/framework7/css/framework7.icons.css';

// import template
import './commons/css/components/animations.css';
import './commons/css/components/inputs.css';
import './commons/css/components/buttons.css';
import './commons/css/components/sheet.css';
import './commons/css/components/status-bar.css';
import './commons/css/components/smart-select.css';
import './commons/css/components/alerts.css';
import './commons/css/layouts/default.css';
import './commons/css/fonts.css';
import './commons/css/icons.css';
import './commons/css/template.css';
import './commons/css/responsive.css';

// import images
import imageLoadingSign from '../assets/commons/images/img-loading.gif';
import imageNotFound from '../assets/commons/images/img-not-found.png';

// import languages
const languages = {
  ar: require('../../resources/languages/ar'),
  en: require('../../resources/languages/en')
};

// application declaration
class App {
  /**
   * Constructor
   */
  constructor() {
    this.config = window.config || {};
    this.debug = this.config.debug;

    // init app
    this.servers = {
      api: window.origin + '/api/v2'
    };
    this.defaultLanguage = 'ar';
    this.availableLanguages = ['ar'];
    this.ui = this.initialize().on('after-init', () => {
      $('.statusbar').hide();
      $('.view-main').show();
      this.getAnalytics();
    }).emit('after-init');

    // history management 
    this.history().push(document.location.toString());
    window.onpopstate = event => {
      if ($('body').hasClass('iframe')) {
        window.parent.postMessage({
          type: 'history-go-back'
        }, '*');
        return;
      }
      const last = this.history().pop();
      if (typeof last == 'object' && typeof last.state === 'object' && last.state.close) {
        $$(last.state.close).trigger('click');
        $$(last.state.close).trigger('tap');
        $$(last.state.close).trigger('close');
        $$(last.state.close).trigger('keydown');
      }
    };

    // on error 
    window.onerror = function (message, url, line, col, error) {
      $.post('/browser-errors', {
        page: window.location.href,
        url: url,
        error: message,
        stack: error instanceof Error ? error.stack : '',
        line: line,
        col: col
      });
    };

    // on message received
    window.addEventListener('message', event => {
      if (typeof event.data == 'object' && event.data.type == 'history-go-back') {
        window.onpopstate();
        event.stopPropagation();
      }
    });
  }

  /**
   * Initialize the app
   * 
   * @returns {void}
   */
  initialize() {
    const self = this;
    const ui = new Framework7({
      // App root element
      root: '#app',
      // App Name
      name: this.translate('Local business directory'),
      // App id
      id: 'local.business.directory',
      // Enable swipe panel
      panel: {
        swipe: 'right'
      },
      // Add default routes
      routes: [],
      // ios view
      view: {
        iosDynamicNavbar: true
      },
      // Touch module
      touch: {
        tapHold: true
      },
      // theme
      theme: 'ios',
      // Extended by modal component:
      modal: {},
      // sheet
      sheet: {},
      // history
      history: {},
      // methods
      methods: {
        /**
         * Fill out the form with the data
         */
        fillFromData: function (form, data) {
          new Promise(function (resolve) {
            const formData = JSON.parse(JSON.stringify(data));
            let nCheck = 0;
            (function check() {
              if (nCheck > 5) return resolve();
              self.ui.form.fillFromData(form, formData);
              if (!formData || typeof formData !== 'object') {
                return resolve();
              }
              let i = 0;
              let keys = [];
              for (let key in formData) {
                keys.push(key);
                let val = formData[key];
                let isSet = false;
                const $el = $(form).find('[name="' + key + '"]');
                if (!$el.length) {
                  console.error('input', key, 'not found !');
                  delete formData[key];
                  return;
                }
                if (String($el.get(0).tagName).toLowerCase() === 'input') {
                  if (['text', 'tel', 'email', 'hidden'].indexOf($el.attr('type').toLowerCase()) != -1 && $el.val() == val) {
                    isSet = true;
                  }
                  if ($el.attr('type').toLowerCase() === 'checkbox' && $el.val() == val && $el.is(':checked')) {
                    isSet = true;
                  }
                  if ($el.attr('type').toLowerCase() === 'radio' && $el.val() == val && $el.is(':checked')) {
                    isSet = true;
                  }
                } else if (String($el.get(0).tagName).toLowerCase() === 'select') {
                  let vals;
                  if (!Array.isArray(val)) {
                    vals = [val];
                  } else {
                    vals = val;
                  }
                  let found = 0;
                  let selected = [];
                  vals.forEach(function (val) {
                    const op = $el.find('option[value="' + val + '"]');
                    if (op.length) {
                      found++;
                      if (!$el.data('loading') && op.is(':selected')) {
                        selected.push(op.get(0));
                      }
                    }
                  });
                  if (found != vals.length || selected.length == vals.length) {
                    isSet = true;
                  }
                } else if (String($el.get(0).tagName).toLowerCase() === 'textarea' && $el.val() == val) {
                  isSet = true;
                }
                if (isSet) {
                  i++;
                  delete formData[key];
                } else {
                  console.warn('control', key, 'not set !');
                }
              }
              if (i == keys.length) {
                return resolve();
              }
              nCheck++;
              setTimeout(check, 500);
            })();
          });
        },
        /**
         * Adding a parameter to the URL
         */
        insertParamToUrl(url, key, value) {
          const _url = new URL(/^https|^http/.test(url) ? url : 'https://locals.works' + url);
          _url.searchParams.delete(key);
          _url.searchParams.append(key, value);
          return /^https|^http/.test(url) ? _url.toString() : _url.pathname + '?' + _url.searchParams.toString() + (_url.hash || '');
        },
        /**
         * Gen random number
         */
        getRandomNumber(nbLength) {
          const gen = n => [...Array(n)].map(_ => Math.random() * 10 | 0).join``;
          return (1 + Math.random() * 9 | 0) + (nbLength == 1 ? '' : gen(nbLength - 1 || 5));
        }
      },
      // events
      on: {
        smartSelectBeforeOpen: function (ss) {
          ss.getItemsData = ss.app.params.smartSelect.getItemsData;
        },
        smartSelectOpen: ss => {
          this.history().push(location.href.replace(location.hash, '') + '#smart-select-' + ss.id, {
            close: '.smart-select-sheet .sheet-close'
          });
        },
        smartSelectClose: ss => {
          this.history().pop(location.href.replace(location.hash, '') + '#smart-select-' + ss.id);
        },
        sheetOpen: function (sheet) {
          const $el = $(sheet.el);
          if (!$el.hasClass('sheet-modal-center') || $(window).width() <= 768) return;
          const availableHeight = $(window).height() - $el.height();
          const availableWidth = $(window).width() - $el.width();
          $el.css('transform', 'translate3d(0, 99%, 0)');
          $el.css('margin', '0 ' + Math.ceil(availableWidth / 2) + 'px');
        },
        sheetOpened: function (sheet) {
          const $el = $(sheet.el);
          if (!$el.hasClass('sheet-modal-center') || $(window).width() <= 768) return;
          const availableHeight = $(window).height() - sheet.el.offsetHeight;
          const availableWidth = $(window).width() - $el.width();
          $el.css('transform', 'translate3d(0, -' + Math.ceil(availableHeight / 2) + 'px, 0)');
          $el.css('margin', '0 ' + Math.ceil(availableWidth / 2) + 'px');
        },
        sheetClose: function (sheet) {
          const $el = $(sheet.el);
          $el.css('transform', '');
        },
        sheetClosed: function (sheet) {
          const $el = $(sheet.el);
          $el.css('margin', '');
        }
      }
    });

    // bind utilities 
    this.utilities = Utilities;

    // Initialize View 
    this.view = ui.views.create('.view-main', {
      url: '/'
    });
    this.view.caches = {};
    ui.params.dialog.buttonOk = this.translate('Ok');
    ui.params.dialog.buttonCancel = this.translate('Cancel');
    ui.params.smartSelect.routableModals = false;
    ui.params.smartSelect.sheetCloseLinkText = this.translate('Close');
    ui.params.smartSelect.searchbarPlaceholder = this.translate('Search');
    ui.params.smartSelect.searchbarDisableButton = false;
    ui.params.smartSelect.getItemsData = function () {
      const ss = this;
      const theme = ss.app.theme;
      const items = [];
      let previousGroupEl;
      ss.$selectEl.find('option').each((index, optionEl) => {
        const $optionEl = $$(optionEl);
        const optionData = $optionEl.dataset();
        const optionImage = optionData.optionImage || ss.params.optionImage;
        const optionIcon = optionData.optionIcon || ss.params.optionIcon;
        const optionIconIos = theme === 'ios' && (optionData.optionIconIos || ss.params.optionIconIos);
        const optionIconMd = theme === 'md' && (optionData.optionIconMd || ss.params.optionIconMd);
        const optionIconAurora = theme === 'aurora' && (optionData.optionIconAurora || ss.params.optionIconAurora);
        const optionHasMedia = optionImage || optionIcon || optionIconIos || optionIconMd || optionIconAurora;
        const optionColor = optionData.optionColor;
        let optionClassName = optionData.optionClass || '';
        if ($optionEl[0].disabled) optionClassName += ' disabled';
        const optionGroupEl = $optionEl.parent('optgroup')[0];
        const optionGroupLabel = optionGroupEl && optionGroupEl.label;
        let optionIsLabel = false;
        if (optionGroupEl && optionGroupEl !== previousGroupEl) {
          optionIsLabel = true;
          previousGroupEl = optionGroupEl;
          items.push({
            groupLabel: optionGroupLabel,
            isLabel: optionIsLabel
          });
        }
        items.push({
          alias: optionData.alias || '',
          hideAlias: ss.$el.hasClass('smart-select-hide-alias'),
          value: $optionEl[0].value,
          text: $optionEl[0].textContent.trim(),
          selected: $optionEl[0].selected,
          groupEl: optionGroupEl,
          groupLabel: optionGroupLabel,
          image: optionImage,
          icon: optionIcon,
          iconIos: optionIconIos,
          iconMd: optionIconMd,
          iconAurora: optionIconAurora,
          color: optionColor,
          className: optionClassName,
          disabled: $optionEl[0].disabled,
          id: ss.id,
          hasMedia: optionHasMedia,
          checkbox: ss.inputType === 'checkbox',
          radio: ss.inputType === 'radio',
          inputName: ss.inputName,
          inputType: ss.inputType
        });
      });
      ss.items = items;
      return items;
    };
    ui.params.smartSelect.renderSheet = function () {
      const ss = this;
      const cssClass = ss.params.cssClass;
      ss.onOpen = function (type, containerEl) {
        const ss = this;
        const app = ss.app;
        const $containerEl = $$(containerEl);
        ss.$containerEl = $containerEl;
        ss.openedIn = type;
        ss.opened = true;
        $$('<div class="sheet-backdrop smart-select-backdrop backdrop-in"></div>').insertBefore($containerEl);

        // Init VL
        if (ss.params.virtualList) {
          ss.vl = app.virtualList.create({
            el: $containerEl.find('.virtual-list'),
            items: ss.items,
            renderItem: ss.renderItem.bind(ss),
            height: ss.params.virtualListHeight,
            searchByItem(query, item) {
              if (item.text && item.text.toLowerCase().indexOf(query.trim().toLowerCase()) >= 0) return true;
              return false;
            }
          });
        }
        if (ss.params.scrollToSelectedItem) {
          ss.scrollToSelectedItem();
        }

        // Init SB
        if (ss.params.searchbar) {
          let $searchbarEl = $containerEl.find('.searchbar');
          if (type === 'page' && app.theme === 'ios') {
            $searchbarEl = $(app.navbar.getElByPage($containerEl)).find('.searchbar');
          }
          if (ss.params.appendSearchbarNotFound && (type === 'page' || type === 'popup')) {
            let $notFoundEl = null;
            if (typeof ss.params.appendSearchbarNotFound === 'string') {
              $notFoundEl = $(`<div class="block searchbar-not-found">${ss.params.appendSearchbarNotFound}</div>`);
            } else if (typeof ss.params.appendSearchbarNotFound === 'boolean') {
              $notFoundEl = $('<div class="block searchbar-not-found">Nothing found</div>');
            } else {
              $notFoundEl = ss.params.appendSearchbarNotFound;
            }
            if ($notFoundEl) {
              $containerEl.find('.page-content').append($notFoundEl[0]);
            }
          }
          const searchbarParams = ui.utils.extend({
            el: $searchbarEl,
            backdropEl: $containerEl.find('.searchbar-backdrop'),
            searchContainer: `.smart-select-list-${ss.id}`,
            searchIn: '.item-title',
            notFoundEl: '.searchbar-not-found',
            removeDiacritics: true
          }, typeof ss.params.searchbar === 'object' ? ss.params.searchbar : {});
          ss.searchbar = app.searchbar.create(searchbarParams);
          ss.searchbar.search = ui.searchbar.search;
        }

        // Check for max length
        if (ss.maxLength) {
          ss.checkMaxLength();
        }

        // Close on select
        if (ss.params.closeOnSelect) {
          ss.$containerEl.find(`input[type="radio"][name="${ss.inputName}"]:checked`).parents('label').once('click', () => {
            ss.close();
          });
        }

        // Attach input events
        ss.attachInputsEvents();
        ss.$el.trigger('smartselect:open');
        ss.emit('local::open smartSelectOpen', ss);
      };
      ss.onClose = function () {
        var ss = this;
        self._history;
        self.history().pop(location.href + '#smart-select-' + ss.id);
        if (ss.destroyed) {
          return;
        }
        $('.smart-select-backdrop.backdrop-in').remove();

        // Destroy VL
        if (ss.vl && ss.vl.destroy) {
          ss.vl.destroy();
          ss.vl = null;
          delete ss.vl;
        }

        // Destroy SB
        if (ss.searchbar && ss.searchbar.destroy) {
          ss.searchbar.destroy();
          ss.searchbar = null;
          delete ss.searchbar;
        }
        // Detach events
        ss.detachInputsEvents();
        ss.$el.trigger('smartselect:close');
        ss.emit('local::close smartSelectClose', ss);
      };
      const sheetHtml = `
				<div class="sheet-modal smart-select-sheet ${cssClass}" data-select-name="${ss.selectName}">
					<div class="toolbar toolbar-top ${ss.params.toolbarColorTheme ? `color-${ss.params.toolbarColorTheme}` : ''}">
						<div class="toolbar-inner">
							<div class="left"></div>
							<div class="right">
								<a class="link sheet-close">${ss.params.sheetCloseLinkText}</a>
							</div>
						</div>
					</div>
					<div class="sheet-modal-inner">
						${ss.params.searchbar ? `<div class="subnavbar">${ss.renderSearchbar()}</div>` : ''}
						<div class="page-content">
							${ss.params.appendSearchbarNotFound ? `<div class="block searchbar-not-found">${ss.params.appendSearchbarNotFound}</div>` : ''}
							<div class="list smart-select-list-${ss.id} ${ss.params.virtualList ? ' virtual-list' : ''} ${ss.params.formColorTheme ? `color-${ss.params.formColorTheme}` : ''}">
								<ul>${!ss.params.virtualList && ss.renderItems(ss.items)}</ul>
							</div>
						</div>
					</div>
				</div>
			`;
      return sheetHtml;
    };
    ui.params.smartSelect.renderItem = function (item, index) {
      const ss = this;
      function getIconContent(iconValue = '') {
        if (iconValue.indexOf(':') >= 0) {
          return iconValue.split(':')[1];
        }
        return '';
      }
      function getIconClass(iconValue = '') {
        if (iconValue.indexOf(':') >= 0) {
          let className = iconValue.split(':')[0];
          if (className === 'f7') className = 'f7-icons';
          if (className === 'material') className = 'material-icons';
          return className;
        }
        return iconValue;
      }
      let itemHtml;
      if (item.isLabel) {
        itemHtml = `<li class="item-divider">${item.groupLabel}</li>`;
      } else {
        let selected = item.selected;
        let disabled;
        if (ss.params.virtualList) {
          const ssValue = ss.getValue();
          selected = ss.multiple ? ssValue.indexOf(item.value) >= 0 : ssValue === item.value;
          if (ss.multiple) {
            disabled = ss.multiple && !selected && ssValue.length === parseInt(ss.maxLength, 10);
          }
        }
        const {
          icon,
          iconIos,
          iconMd,
          iconAurora
        } = item;
        const hasIcon = icon || iconIos || iconMd || iconAurora;
        const iconContent = getIconContent(icon || iconIos || iconMd || iconAurora || '');
        const iconClass = getIconClass(icon || iconIos || iconMd || iconAurora || '');
        itemHtml = `
					<li class="${item.className || ''}${disabled ? ' disabled' : ''}">
						<label class="item-${item.inputType} item-content">
							<input type="${item.inputType}" name="${item.inputName}" value="${item.value}" ${selected ? 'checked' : ''}/>
							<i class="icon icon-${item.inputType}"></i>
							${item.hasMedia ? `
							<div class="item-media">
								${hasIcon ? `<i class="icon ${iconClass}">${iconContent}</i>` : ''}
								${item.image ? `<img src="${item.image}">` : ''}
							</div>
							` : ''}
							<div class="item-inner">
								<div class="item-title${item.color ? ` text-color-${item.color}` : ''}">${item.text} ${item.alias ? '<span class="item-alias" style="' + (item.hideAlias ? 'display:none;' : '') + '">' + item.alias + '</span>' : ''}</div>
							</div>
						</label>
					</li>
				`;
      }
      return itemHtml;
    };
    ui.params.imageForm = {
      renderSheet(type, image) {
        let title;
        let actions = [`<a class="button button-large button-outline-ios sheet-close" style="margin-top: 15px;">${self.translate("Cancel")}</a>`];
        switch (type) {
          case 'remove':
            title = self.translate('Remove image');
            actions.unshift(`<a class="button button-large button-fill remove-image color-red">${self.translate("Remove")}</a>`);
            break;
          case 'add':
          default:
            title = self.translate('Add new image');
            actions.unshift(`<a class="button button-large button-fill add-image">${self.translate("Add")}</a>`);
        }
        const sid = 's-' + ui.methods.getRandomNumber(6);
        let tmpl = `
					<div class="sheet-modal sheet-modal-center form-image" id="form-image" style="">
						<div class="toolbar">
							<div class="toolbar-inner">
								<div class="left"></div>
								<div class="title">${title}</div>
								<div class="right"></div>
							</div>
						</div>
						<div class="sheet-modal-inner">
							<form name="image-form" id="image-form">
								<div class="block list-inputs list block-strong inset no-margin">
									<div class="image">
										<!-- <img src="${imageLoadingSign}" class="image-is-loading" /> -->
									</div>
								</div>
							</form>
							<div class="padding-horizontal padding-bottom">
								${actions.join('')}
							</div>
						</div>
					</div>
				`;
        const $tmpt = $('body').append($(tmpl).addClass(sid).css('display', 'none')).find('.' + sid);
        const autoDim = parseInt(getComputedStyle($tmpt.get(0)).getPropertyValue('--sheet-modal-auto-dimensions') || 0);
        const sWidth = Math.floor($(window).width() * (autoDim ? 75 : 100) / 100);
        const sHeight = Math.floor($(window).height() * (autoDim ? 75 : 100) / 100);
        const tWidth = $tmpt.actual('width');
        const tHeight = $tmpt.actual('height');
        const icWidth = $tmpt.find('.image').actual('width');
        const icHeight = sHeight - tHeight;
        const maxWidth = autoDim ? sWidth - tWidth - 50 < 0 ? sWidth - 50 : sWidth - tWidth - 50 : icWidth;
        const maxHeight = autoDim ? sHeight - tHeight - 50 < 0 ? sHeight - 50 : sHeight - tHeight - 50 : icHeight;
        let iWidth;
        let iHeight;
        if (image.width > maxWidth) {
          iWidth = maxWidth;
          iHeight = image.height / image.width * iWidth;
          if (iHeight > maxHeight) {
            iWidth = image.width / image.height * maxHeight;
            iHeight = maxHeight;
          }
        } else if (image.height > maxHeight) {
          iWidth = image.width / image.height * maxHeight;
          iHeight = maxHeight;
          if (iWidth > maxWidth) {
            iWidth = maxWidth;
            iHeight = image.height / image.width * iWidth;
          }
        } else {
          iWidth = image.width;
          iHeight = image.height;
        }
        $(image).width(iWidth);
        $(image).height(iHeight);
        $tmpt.find('.image').empty().append(image);
        tmpl = $tmpt.get(0).outerHTML;
        $tmpt.remove();
        return tmpl;
      },
      resize(image, container) {
        const $container = $(container);
        const sWidth = $container.width();
        const sHeight = $container.height();
        const maxWidth = $container.find('.image').width();
        $container.find('.image').hide();
        const maxHeight = $(window).height() - (sHeight - 30);
        $container.find('.image').show();
        let iWidth;
        let iHeight;
        if (image.width > maxWidth && image.height > maxHeight) {} else if (image.width > maxWidth) {} else if (image.height > maxHeight) {} else {
          iWidth = image.width;
          iHeight = image.height;
        }

        //let iWidth = cWidth;
        //let iHeight = (image.height/image.width)*iWidth;

        if (maxHeight - iHeight < 20) {
          //iWidth = (image.width/image.height)*maxHeight;
          //iHeight = maxHeight;
          $(image).width(iWidth);
          $(image).height(iHeight);
          $(image).addClass('portrait');
        } else {
          $(image).width(iWidth);
          $(image).height(iHeight);
          $(image).addClass('landscape');
        }
        return image;
      }
    };
    ui.searchbar.search = function (query, internal) {
      const sb = this;
      const Utils = ui.utils;
      sb.previousQuery = sb.query || '';
      if (query === sb.previousQuery) return sb;
      if (!internal) {
        if (!sb.enabled) {
          sb.enable();
        }
        sb.$inputEl.val(query);
        sb.$inputEl.trigger('input');
      }
      sb.query = query;
      sb.value = query;
      const {
        $searchContainer,
        $el,
        $foundEl,
        $notFoundEl,
        $hideOnSearchEl,
        isVirtualList
      } = sb;

      // Hide on search element
      if (query.length > 0 && $hideOnSearchEl) {
        $hideOnSearchEl.addClass('hidden-by-searchbar');
      } else if ($hideOnSearchEl) {
        $hideOnSearchEl.removeClass('hidden-by-searchbar');
      }
      // Add active/inactive classes on overlay
      if ($searchContainer && $searchContainer.length && $el.hasClass('searchbar-enabled') || sb.params.customSearch && $el.hasClass('searchbar-enabled')) {
        if (query.length === 0) {
          sb.backdropShow();
        } else {
          sb.backdropHide();
        }
      }
      if (sb.params.customSearch) {
        $el.trigger('searchbar:search', {
          query,
          previousQuery: sb.previousQuery
        });
        sb.emit('local::search searchbarSearch', sb, query, sb.previousQuery);
        return sb;
      }
      let foundItems = [];
      let vlQuery;
      if (isVirtualList) {
        sb.virtualList = $searchContainer[0].f7VirtualList;
        if (query.trim() === '') {
          sb.virtualList.resetFilter();
          if ($notFoundEl) $notFoundEl.hide();
          if ($foundEl) $foundEl.show();
          $el.trigger('searchbar:search', {
            query,
            previousQuery: sb.previousQuery
          });
          sb.emit('local::search searchbarSearch', sb, query, sb.previousQuery);
          return sb;
        }
        vlQuery = sb.params.removeDiacritics ? Utils.removeDiacritics(query) : query;
        if (sb.virtualList.params.searchAll) {
          foundItems = sb.virtualList.params.searchAll(vlQuery, sb.virtualList.items) || [];
        } else if (sb.virtualList.params.searchByItem) {
          for (let i = 0; i < sb.virtualList.items.length; i += 1) {
            if (sb.virtualList.params.searchByItem(vlQuery, sb.virtualList.params.items[i], i)) {
              foundItems.push(i);
            }
          }
        }
      } else {
        let values;
        if (sb.params.removeDiacritics) values = Utils.removeDiacritics(query.trim().toLowerCase()).split(' ');else {
          values = query.trim().toLowerCase().split(' ');
        }
        $searchContainer.find(sb.params.searchItem).removeClass('hidden-by-searchbar').each((itemIndex, itemEl) => {
          const $itemEl = $(itemEl);
          let compareWithText = [];
          let $searchIn = sb.params.searchIn ? $itemEl.find(sb.params.searchIn) : $itemEl;
          if (sb.params.searchIn === sb.params.searchItem) {
            $searchIn = $itemEl;
          }
          $searchIn.each((searchInIndex, searchInEl) => {
            const $searchInEl = $(searchInEl);
            let itemText = $searchInEl.text().trim().toLowerCase();
            if ($searchInEl.data('alias')) {
              compareWithText.push($searchInEl.data('alias'));
            }
            if (sb.params.removeDiacritics) {
              itemText = Utils.removeDiacritics(itemText);
            }
            compareWithText.push(itemText);
          });
          const _compareWithText = compareWithText.join(' ');
          let wordsMatch = 0;
          let isClosest = false;
          for (let i = 0; i < values.length; i += 1) {
            let closestWords = Utilities.foundClosestWords(values[i], compareWithText, 0.19);
            if (!isClosest) isClosest = closestWords.length ? true : false;
            if (_compareWithText.indexOf(values[i]) >= 0) {
              wordsMatch += 1;
            }
          }
          if (isClosest) {
            foundItems.push($itemEl[0]);
          } else {
            if (wordsMatch !== values.length && !(sb.params.ignore && $itemEl.is(sb.params.ignore))) {
              $itemEl.addClass('hidden-by-searchbar');
            } else {
              foundItems.push($itemEl[0]);
            }
          }
        });
        if (sb.params.hideDividers) {
          $searchContainer.find(sb.params.searchGroupTitle).each((titleIndex, titleEl) => {
            const $titleEl = $(titleEl);
            const $nextElements = $titleEl.nextAll(sb.params.searchItem);
            let hide = true;
            for (let i = 0; i < $nextElements.length; i += 1) {
              const $nextEl = $nextElements.eq(i);
              if ($nextEl.is(sb.params.searchGroupTitle)) break;
              if (!$nextEl.hasClass('hidden-by-searchbar')) {
                hide = false;
              }
            }
            const ignore = sb.params.ignore && $titleEl.is(sb.params.ignore);
            if (hide && !ignore) $titleEl.addClass('hidden-by-searchbar');else $titleEl.removeClass('hidden-by-searchbar');
          });
        }
        if (sb.params.hideGroups) {
          $searchContainer.find(sb.params.searchGroup).each((groupIndex, groupEl) => {
            const $groupEl = $(groupEl);
            const ignore = sb.params.ignore && $groupEl.is(sb.params.ignore);
            // eslint-disable-next-line
            const notHidden = $groupEl.find(sb.params.searchItem).filter((index, el) => {
              return !$(el).hasClass('hidden-by-searchbar');
            });
            if (notHidden.length === 0 && !ignore) {
              $groupEl.addClass('hidden-by-searchbar');
            } else {
              $groupEl.removeClass('hidden-by-searchbar');
            }
          });
        }
      }
      if (foundItems.length === 0) {
        if ($notFoundEl) $notFoundEl.show();
        if ($foundEl) $foundEl.hide();
      } else {
        if ($notFoundEl) $notFoundEl.hide();
        if ($foundEl) $foundEl.show();
      }
      if (isVirtualList && sb.virtualList) {
        sb.virtualList.filterItems(foundItems);
      }
      $el.trigger('searchbar:search', {
        query,
        previousQuery: sb.previousQuery,
        foundItems
      });
      sb.emit('local::search searchbarSearch', sb, query, sb.previousQuery, foundItems);
      return sb;
    };

    // reset input field validation status
    $('select,input').on('change', function () {
      if ($(this).closest('.item-input').hasClass('error-controlled')) {
        return;
      }
      $(this).closest('.item-input').removeClass('error-field');
      $(this).parent().removeClass('error-field');
    });

    // on doc ready 
    $(document).ready(ev => {
      // page desc 
      $('.page-desc').each(function () {
        const iconWidth = $(this).find('.icon').outerWidth();
        const textWidth = $(this).find('.text').outerWidth();
        const offsetWidth = 30;
        if ($(this).width() < iconWidth + textWidth + offsetWidth) {
          $(this).addClass('overflowed');
          $(this).find('.text').css({
            minWidth: $(this).width() - iconWidth - offsetWidth + 'px',
            whiteSpace: 'unset'
          });
        }
      });

      // put footer at the end of the view
      function setFooterOnBottom() {
        const footer = document.querySelector('footer');
        const html = document.querySelector('html');
        let diff = html.offsetHeight - footer.offsetTop - footer.offsetHeight;
        if (diff < 0) {
          return;
        }
        footer.style.position = "relative";
        if (footer.style.top) {
          diff += parseInt(footer.style.top) || 0;
        }
        footer.style.top = diff + "px";
      }
      ;
      setFooterOnBottom();
      if (typeof ResizeObserver == 'function') {
        new ResizeObserver(setFooterOnBottom).observe($('.page-content').get(0));
      }
    });

    // include system message
    $(".page-content").prepend($(".system-message"));

    // lazy loading images
    const _lazy_load_images = $('img[data-image-loading!=""]').get();
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const image = entry.target;
          if (image.dataset.imageLoading) {
            if (image.dataset.imageLoading !== 'undefined') image.src = image.dataset.imageLoading;else image.src = imageNotFound;
          }
          image.loading = 'eager'; // Set loading to "eager" for immediate loading after intersection
          observer.unobserve(image); // Stop observing this image
        }
      });
    });

    _lazy_load_images.forEach(image => {
      observer.observe(image);
    });

    // open craftsman profile
    $('.craftsman-profile-link').closest('.main-data').on('click', ev => {
      $(ev.currentTarget).find('.craftsman-profile-link').trigger('click');
    });
    $('.craftsman-profile-link').on('click', ev => {
      ev.preventDefault();
      ev.stopPropagation();
      craftsmanTools.openProfile($(ev.currentTarget).attr('href'));
    });

    // menu in user menu
    $('.user-menu').on('menu:opened', function () {
      $('[data-popover]').each(function () {
        const $linkMenuInMenu = $(this);
        const position = $linkMenuInMenu.offset();
        $($linkMenuInMenu.data('popover')).attr('data-backdrop', 'false');
        $($linkMenuInMenu.data('popover')).attr('data-target-width', Math.floor($linkMenuInMenu.outerWidth()));
        $($linkMenuInMenu.data('popover')).on('popover:open', function () {
          $(this).css({
            width: Math.floor($linkMenuInMenu.outerWidth()),
            marginTop: $linkMenuInMenu.height() - 5 + position.top + 'px'
          });
          $linkMenuInMenu.addClass('menu-in-menu-opened');
        });
        $($linkMenuInMenu.data('popover')).on('popover:close', function () {
          $linkMenuInMenu.removeClass('menu-in-menu-opened');
        });
      });
    });

    // finally
    return ui;
  }

  /**
   * Get analytics
   * 
   * @returns {void}
   */
  getAnalytics() {
    return this.config.debug ? null : firebase.analytics();
  }

  /**
   * Track app event
   * 
   * @returns {void}
   */
  trackEvent(name, params) {
    if (!this.getAnalytics()) {
      return;
    }
    if (!params || typeof params != 'object' || Object.getPrototypeOf(params) !== Object.prototype) {
      params = {};
    }
    this.getAnalytics().logEvent(name, params);
  }

  /**
   * Get current user language code
   * @return	{String}
   */
  getUserLanguage() {
    let userLanguage = $('html').attr('lang');
    if (this.availableLanguages.indexOf(userLanguage) === -1) {
      console.log('[' + userLanguage + '] is not available ! Set default user language : [' + defaultLanguage + ']');
      userLanguage = this.defaultLanguage;
    }
    return userLanguage;
  }

  /**
   * Translate phrases / words
   * 
   * @returns {String}
   */
  translate(text, params) {
    let userLanguage = this.getUserLanguage();
    text = String(text).toUpperCase().replace('%S', '%s');
    if (!languages[userLanguage]) {
      console.log('Translation to ' + userLanguage + ' is not available !');
    }
    var nText = languages[userLanguage][text];
    if (!nText) {
      console.log('Missing translation', '"' + text + '":{{Text "' + text + '"}}');
      nText = text;
    }
    while (/%s/.test(nText) && Array.isArray(params) && params.length) {
      var str = params.shift();
      nText = nText.replace(/%s/, str);
    }
    return nText;
  }

  /**
   * Event : send http request
   *
   * @return	{Promise}
   */
  sendHttpRequest(options) {
    const self = this;
    if (typeof options != 'object' || !options.url || !/post|get|delete|put|patch/i.test(options.method)) {
      console.error('Bad http request', options);
      return Promise.reject(new ErrorSystem('APP_HTTP_REQUEST_ERROR', 'Bad http request'));
    }
    if (!/http|https/g.test(options.url)) {
      options.url = /^\.\//.test(options.url) ? options.url.substr(1) : this.servers.api + options.url;
    }
    return $.ajax({
      url: options.url,
      type: options.method,
      dataType: options.type || 'json',
      data: options.data
    }).catch(function (error) {
      if (!error.status) {
        throw new ErrorServerUnavailable();
      } else {
        let errorCode = 400;
        let errorMessage = 'unknown';
        if (error.responseJSON && typeof error.responseJSON.error == 'object') {
          errorCode = error.responseJSON.error.code;
          errorMessage = error.responseJSON.error.message;
        }
        return new Promise((resolve, reject) => {
          const errorThrown = new Error(errorMessage);
          errorThrown.code = errorCode;
          if (errorCode == 403 && !$('.dialog-preloader').length) {
            return self.ui.dialog.confirm(self.translate('Authentication required'), undefined, function () {
              window.location.href = '/' + self.getUserLanguage() + '/sign-in';
              return;
            }, function () {
              errorThrown.message = self.translate("Unable to perform the action requested");
              reject(errorThrown);
            });
          }
          throw errorThrown;
        });
      }
    });
  }

  /**
   * history
   */
  history() {
    if (!this._history) {
      this._history = [];
    }
    return {
      push: (url, state) => {
        this._history.push({
          url: url,
          state: state
        });
        window.history.replaceState(state, null, url);
        window.history.pushState(null, null, url);
      },
      pop: url => {
        const current = this._history[this._history.length - 1];
        if (typeof url !== 'undefined' && current.url != url || this._history.length === 1) {
          return;
        }
        const last = this._history.pop();
        const newLast = this._history[this._history.length - 1];
        window.history.replaceState(newLast.state, null, newLast.url);
        return last;
      },
      update: (url, state) => {
        const last = this._history[this._history.length - 1];
        last.url = url;
        if (typeof state !== 'undefined') {
          last.state = state;
        }
        window.history.replaceState(last.state, null, last.url);
      }
    };
  }

  /**
   * logs
   */
  log() {
    var args = Array.prototype.slice.call(arguments); // Make real array from arguments
    let type = 'debug';
    if (args[0] instanceof Error) {
      type = 'error';
      args.unshift("::: Error :::");
    }
    switch (type) {
      case 'error':
        console.error.apply(null, args);
        break;
      case 'debug':
      default:
        console.log.apply(null, args);
        break;
    }
  }
}
export default new App();