/**
 * @package		Al hirafiyin web app
 * @desc		Page script 
 *
 * @copyright	Copyright (C) 2022 Jaayfer abderrahim, Inc. All rights reserved.
 * @license		see LICENSE.txt
 */
import '../css/index.css';
import app from '../../app';
import $ from 'jquery';
// make buttons for supported countries unclickable
$('.supported-countries a').removeClass('external');
new Promise(function (resolve) {
  app.ui.swiper.create('.craftsmen-gallery', {
    pagination: false,
    spaceBetween: 10,
    loop: true,
    slidesPerView: 1,
    centeredSlides: true,
    initialSlide: 1,
    autoplay: {
      delay: 1500
    },
    lazyPreloadPrevNext: 2,
    on: {
      init: function () {
        setTimeout(resolve, 100);
      }
    },
    effect: 'cards',
    speed: 400
  });
}).then(function () {
  return new Promise(function (resolve) {
    app.ui.swiper.create('.img-gallery', {
      pagination: {
        el: '.swiper-pagination'
      },
      spaceBetween: 10,
      loop: true,
      centeredSlides: true,
      initialSlide: 2,
      autoplay: {
        delay: 2500
      },
      lazyPreloadPrevNext: 2,
      on: {
        init: function () {
          setTimeout(resolve, 100);
        }
      },
      effect: 'cards',
      speed: 400,
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 3
        }
      }
    });
  });
});